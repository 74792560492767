import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { Flex } from '$components/layouts';
import { useTranslation } from '$hooks';
import { ICalculatedBasket, IShippingType } from '$models';
import { useBasket, useSite } from '~/store';
import { Text } from '$components/elements/text';
import { Button } from '$components/elements/button';
import { ErrorText } from '$components/elements/error-text';
import { InputField } from '$components/elements/input-field';

type FormData = {
    voucherCode: string;
};

type Props = { basket?: ICalculatedBasket; shipping?: IShippingType; onVoucherFocus?: (focus: boolean) => void };

export const AppVoucher: FC<Props> = ({ basket, shipping, onVoucherFocus }) => {
    const { translate } = useTranslation();
    const { addVoucher, clearVoucher, updatingVoucher } = useBasket();
    const { pushGlobalNotification } = useSite();
    const theme = useTheme();
    const [showVoucher, setShowVoucher] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>();

    const onAddVoucher = (voucherCode: string) => {
        onDelayCloseVoucher();
        if (basket?.vouchers?.length) {
            pushGlobalNotification(
                translate('checkout.voucher.voucherOnlyOneAllowedHeader'),
                translate('checkout.voucher.voucherOnlyOneAllowed'),
                'unavoidable'
            );
        } else {
            addVoucher(voucherCode);
        }
    };
    const onToggleVoucher = () => {
        if (showVoucher) {
            setShowVoucher(false);
            reset();
        } else {
            setShowVoucher(true);
        }
    };

    const onDelayCloseVoucher = () => {
        setTimeout(() => onVoucherFocus?.(false), 1000);
    };

    useEffect(() => {
        if (showVoucher && basket?.vouchers?.length) {
            setShowVoucher(false);
            reset();
        }
    }, [basket]);

    const onSubmit = handleSubmit((data) => onAddVoucher(data.voucherCode));

    return (
        <>
            {basket?.vouchers?.length ? (
                <>
                    {basket?.vouchers?.map((voucher, index) => (
                        <Flex key={index} alignItems="center" justifyContent="space-between" spacingBottom="extrasmall">
                            <Flex gap="extrasmall">
                                <Text noSpacing>{translate('checkout.voucher.voucherLabel')}</Text>
                                <Text noSpacing fontWeight="Bold">
                                    {voucher}
                                </Text>
                            </Flex>
                            <Button variant="custom" onClick={clearVoucher}>
                                <Text color={theme.colors.red}>{`X ${translate(
                                    'checkout.voucher.voucherRemove'
                                )}`}</Text>
                            </Button>
                        </Flex>
                    ))}
                    {!!basket?.vouchers?.length &&
                        basket?.voucherDiscount === 0 &&
                        shipping?.calculatedShippingPrice === shipping?.defaultShippingPrice && (
                            <ErrorText noSpacing>{translate('checkout.voucher.voucherNoEffect')}</ErrorText>
                        )}
                </>
            ) : (
                <>
                    <VoucherToggle variant="inverted" size="sm" isOpen={showVoucher} onClick={onToggleVoucher}>
                        <ButtonText>
                            {showVoucher ? translate('general.close') : translate('checkout.voucher.voucherHeading')}
                        </ButtonText>
                    </VoucherToggle>
                    {showVoucher && (
                        <Flex column spacingBottom="medium">
                            <VoucherForm onSubmit={onSubmit}>
                                <VoucherInputContainer column spacingBottom="small">
                                    <InputField
                                        label={translate('checkout.voucher.voucherInputLabel')}
                                        error={errors.voucherCode}
                                        onFocus={() => onVoucherFocus?.(true)}
                                        onBlurSideEffect={() => onDelayCloseVoucher()}
                                        appStyle
                                        autoCapitalize="none"
                                        {...register('voucherCode', {
                                            required: translate('checkout.voucher.voucherEmpty'),
                                        })}
                                    />
                                    <VoucherButton type="submit" disabled={updatingVoucher} width="content">
                                        {translate('checkout.voucher.voucherAdd')}
                                    </VoucherButton>
                                </VoucherInputContainer>
                            </VoucherForm>
                        </Flex>
                    )}
                </>
            )}
        </>
    );
};

const VoucherForm = styled.form(({ theme }) => ({
    paddingTop: theme.space[3],
}));

const VoucherInputContainer = styled(Flex)({});

const VoucherButton = styled(Button)(({ theme }) => ({
    alignSelf: 'flex-start',

    marginTop: theme.space[5],
    width: '100%',
    marginLeft: 0,
}));

const VoucherToggle = styled(Button)<{ isOpen?: boolean }>(({ theme, isOpen }) => ({
    alignSelf: 'flex-start',
    marginBottom: isOpen ? theme.space[5] : theme.space[9],

    '&:hover, &:active, &:focus': {
        backgroundColor: 'transparent',
    },
}));

const ButtonText = styled(Text)({
    padding: '5px 10px',
});
